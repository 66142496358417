
/*****************************************************************************
 * @class: AuthStore
 *
 * @description: este store es el encargado de manejar la lógica de autoria de
 * la aplicación. Autenticación de usuarios, manejo de credenciales de AWS, etc.
 *****************************************************************************/

import 'aws-sdk/dist/aws-sdk'
import React from 'react'
import { browserHistory } from 'react-router'
import { observable, computed, action, reaction } from 'mobx'
import config from '../config'
import { AppStore, SyncStore, ShopStore, UIStore, TrackingApartments } from '../stores'
import { getExternalConfig, loginIlusia, signupIlusia, getUser, getOrders, getProviderCredentials, recoverPass, signupSSODia, signupMASuser, postProviderCredentials, getAddresses, getOrdersByProvider, externalLoginApartments, getOrdersByBookingId } from '../utils/api'
import { isValidateEmail, synOnEnter } from '../utils'
import { Notification } from '../components/Dialogs/Notification/components'


class AuthStore {

  // Esta variable la utilizo para controlar cuando está todo configurado
  // y que AppStore pueda comenzar a pedir los datos que necesite.
  configured = false

  // interval = undefined

  // Almacena el estado del usuario
  @observable authenticated
  // Almacena el objeto User
  @observable user

  // // Historico de pedidos
  // orders = []

  prevState = '/'

  // Variables booking
  bookingType = null // Generated: 0, Real: 1
  bookingId = null
  apartmentId = null

  /**
   * @func: constructor
   * @param {[Function]} callback
   *
   * @description:
   */
  constructor () {

    const url = new URL(window.location.href);
    const pathName = url.pathname;

    // No inicializamos las demás stores y mostramos pagina de usuario no autenticado
    if (pathName == "/no-autenticado"){
      return;
    }

    AWS.config.region = config.REGION
    this.idParam = url.searchParams.get('id');
    this.typeParam = url.searchParams.get('type');
    this.authenticated = this.isUserAuthenticated();
    this.idParamLocalStorage = localStorage.getItem('idParam');
    this.typeParamLocalStorage = localStorage.getItem('typeParam');
    this.bookingId = localStorage.getItem('bookingId');
    this.bookingType = localStorage.getItem('bookingType');
    this.apartmentId = localStorage.getItem('apartmentId');
    
    // Usuario no está autenticado, no hay parametros para inciar sesion por url y no existen parametros en localStorage
    if (!this.authenticated && !this.idParam && !this.typeParam && !this.idParamLocalStorage && ! this.typeParamLocalStorage){
      window.location.href = "/no-autenticado";
      return;
    }

    // Login with params: id and type
    if (this.idParam && this.typeParam){
      this.clearLocalStorageData();
      this.clearUserCredentials();
      this.user = undefined
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: config.IDENTITY_POOL_ID })
	    AWS.config.credentials.clearCachedId();
      return this.setAWSAPIClient(() => {
        this.externalLogin(this.idParam, this.typeParam)
      })
    }
    
    // Use Params In localStorage for login
    if (!this.authenticated && this.idParamLocalStorage && this.typeParamLocalStorage){
      this.clearLocalStorageData();
      const lastLoginTimestamp = localStorage.getItem('lastLoginTimestamp');
      if (!lastLoginTimestamp){
        window.location.href = "/no-autenticado";
        return;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      const lastLoginTime = parseInt(lastLoginTimestamp) / 1000;
      const timeDifference = currentTime - lastLoginTime;
      console.log("timeDifference", timeDifference);
      if (timeDifference > config.MAX_AUTOLOGIN_SESSION_TIME) {
        window.location.href = "/no-autenticado";
        return;
      }

      this.clearUserCredentials()
      this.user = undefined
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: config.IDENTITY_POOL_ID })
	    AWS.config.credentials.clearCachedId()
      return this.setAWSAPIClient(() => {
        this.externalLogin(this.idParamLocalStorage, this.typeParamLocalStorage)
      })
    }

    // Check Session
    console.log("Checking Session...");
    this.setAWSCredentialsParams(() => {
      this.setAWSAPIClient(() => {
        getExternalConfig()
          .then(data => {
            config.IDENTITY_POOL_ID = data.cognito_pool_id
            config.S3_IMAGES = data.s3_images
            AppStore.allergens = data.allergens
            AppStore.welfares = data.welfares
            AppStore.countryname = data.provider.country.country;
            AppStore.postalcode = data.provider.postalcode
            AppStore.idzone = data.provider.idzone
            AppStore.providername = data.provider.providername
            AppStore.idprovider = data.provider.idprovider
            AppStore.provider = data.provider
            AppStore.provider.provider = "ILUSIA";
            console.log("Set User");
            UIStore.hideLoading();

            config.CART_CONFIG.booking_type = this.bookingType;
            this.setUser(() => {
              this.configured = true;

              if (this.bookingType === "1" ){ // Reserve Id Real
                TrackingApartments.login_with_qr(this.apartmentId);
              } else {
                TrackingApartments.login_with_booking(this.apartmentId);
              }

              AppStore.initialize()
              if (ShopStore.quantity > 0 || SyncStore.initialized) {
                SyncStore.initialize(() => {
                  //UIStore.showLoading()
                  synOnEnter()
                })
              }
            })

          })
          .catch(error => {
            console.log(error);
          })
      })
    })
  }

  clearLocalStorageData(){
    localStorage.removeItem('idParam');
    localStorage.removeItem('typeParam');
    localStorage.removeItem('bookingId');
    localStorage.removeItem('bookingType');
  }

  /**
   * @func: setAWSCredentialsParams
   * @param {[Function]} callback
   *
   * @description: Setea los parámetros para pedir las credenciales
   * de AWS. Si el usuario está autenticado seteamos los parámetros
   * que tenemos en sessionStorage, de lo contrario tan solo pasamos
   * IdentityPoolId para obtener las credenciales de usuario anónimo.
   *
   * NOTE: se supone que el 'Token' que deberíamos pasar no es el
   * que devuelve el login si no el 'sessionToken' que está en el
   * objeto 'AWS.config.credentials'. En cualquier caso así funciona.
   */
  setAWSCredentialsParams (callback) {
    let options

    options = {
      IdentityId: sessionStorage.getItem('identityId'),
      IdentityPoolId: config.IDENTITY_POOL_ID,
      Logins: { 'cognito-identity.amazonaws.com': sessionStorage.getItem('accessToken') }
    }

    this.setAWSCredentials(options, callback)
  }


  /**
   * @func: setAWSCredentials
   * @param {[Object]} options
   * @param {[Function]} callback
   * @return {[Function]} callback
   *
   * @description: Setea las credenciales de AWS.
   * Si el callback del método 'get' devuelve un error es que el
   * 'Token' de usuario ha caducado y no podemos refrescarlo así
   * que mostramos una notificación y redirigimos a /no-autenticado.
   */
  setAWSCredentials (options, callback) {
    // NOTE: a ver esto como funciona
    // TODO: si no rula borrarlo
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(options)
    AWS.config.credentials.get(error => {
      if (error) {
        window.location.href = "/no-autenticado";
      }
      if (callback && typeof callback === 'function') return callback(AWS.config.credentials)
    })
  }

  refreshAWSCredentials () {
    console.info('este es el accesstoken', sessionStorage.getItem('accessToken'))
    this.interval = window.setInterval(() => {
      if (AWS.config.credentials.needsRefresh()) {
        console.log("Autologin user");
        UIStore.showLoading();
        externalLoginApartments(localStorage.getItem('idParam'), localStorage.getItem('typeParam')).then(data => {
          if (!data.IdentityId || data.IdentityId === null) {
            UIStore.hideModal()
            UIStore.hideLoading()
            UIStore.showNotification({ type: 'danger', content: () => <Notification message="Se ha producido un error" /> })
            window.location.href = "/no-autenticado";
          } else {
            UIStore.hideModal()
            this.clearUserCredentials()
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: config.IDENTITY_POOL_ID })
            AWS.config.credentials.clearCachedId()
            
            const options = {
              IdentityId: data.IdentityId,
              IdentityPoolId: config.IDENTITY_POOL_ID,
              Logins: { 'cognito-identity.amazonaws.com': data.Token }
            }

            this.setAWSCredentials(options, (newCredentials) => {
              console.info('%cUsuario autologueado con exito!', 'color: green')
              console.log(AWS.config.credentials.sessionToken, data.IdentityId);
              this.storeUserCredentials(data.Token, data.IdentityId);
              this.setAWSAPIClient();
            })

            UIStore.hideLoading()
          }
        })
      } else {
        console.log("No es necesario renovar credenciales")
      }
    }, 18000)
  }

  /**
   * @func: setAWSAPIClient
   * @param {[Function]} callback
   * @return {[Function]} callback
   *
   * @description: Crea un cliente para la API de Ilusia.
   * Si existen credentials las utiliza, de lo contrario tan solo le
   * pasamos el API_KEY.
   */
  setAWSAPIClient (callback) {
    config.API = apigClientFactory.newClient({
      accessKey: AWS.config.credentials.accessKeyId,
      secretKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken,
      apiKey: config.API_KEY,
      region: config.REGION
		})

    if (callback && typeof callback === 'function') return callback()
  }

  externalLogin = (id, type, callback) => {
    if (this.prevState == "/zona-usuario") this.prevState = '/'
    externalLoginApartments(id, type)
    .then(data => {
      if (!data.IdentityId || data.IdentityId === null || !data.lodging_id) {
        UIStore.hideModal()
        UIStore.hideLoading()
        UIStore.showNotification({ type: 'danger', content: () => <Notification message="Se ha producido un error, por favor, inténtalo de nuevo." /> })
      } else {

        UIStore.hideModal()
        
        const options = {
          IdentityId: data.IdentityId,
      		IdentityPoolId: config.IDENTITY_POOL_ID,
      		Logins: { 'cognito-identity.amazonaws.com': data.Token }
        }
        
        this.setAWSCredentials(options, (newCredentials) => {
          console.info('%cUsuario logueado con exito!', 'color: green')
          this.storeUserCredentials(data.Token, data.IdentityId)
          localStorage.setItem('idParam', id);
          localStorage.setItem('typeParam', type);
          localStorage.setItem('lastLoginTimestamp',  Date.now());
          localStorage.setItem('apartmentId', data.lodging_id);

          let bookingType = 0;

          if (data.booking_id){
            localStorage.setItem("bookingId", data.booking_id);
            bookingType = 1;
          } else {
            localStorage.setItem("bookingId", Math.random().toString(36).substring(2));
          }

          localStorage.setItem("bookingType", bookingType);
          window.location.href = "/";
        })   
      }
    })
  }

  refreshAddresses(){
    return new Promise((resolve, reject)=>{
      getUser()
      .then(data => {
        getAddresses()
        .then(resp =>{
          this.user = {...data, addresses: resp}; 
          console.log('In getAddresses of authStore');
          resolve(200)
        })
        .catch(err =>{
          this.user = {...data, addresses: []}
          reject(401)
        })
      })
      .catch(x => reject(401))
    })
  }

  /**
   * @func: setUser
   *
   * @description: setea los datos de usuario autenticado.
   * Pide los datos a la API Ilusia y los almacena en la variable user.
   */
  setUser (callback) {
    getUser().then(data => {
      getAddresses()
      .then(resp =>{this.user = {...data, addresses: resp}; console.log('In getAddresses of authStore');})
      .catch(err =>{this.user = {...data, addresses: []}})
      this.refreshAWSCredentials()
      if (callback && typeof callback === 'function') return callback(this.user)
    })
  }


  /**
   * @description: recupera los pedidos realizados por el usuario y los ordena
   */
  getProcessedOrders (callback) {
    console.log("getProcessedOrders", this.bookingId);

    return getOrdersByBookingId(this.bookingId)
      .then(data => {
        AppStore.orders = data;
        return data;
      })
      .catch(err => {
        console.log(err);
        AppStore.orders = [];
        return [];
    });
  }

  /**
   * @func: storeUserCredentials
   * @param {[String]} token
   *
   * @description: almacena en sessionStorage las credenciales del usuário
   */
  storeUserCredentials (token, identityId) {
    sessionStorage.setItem('accessToken', token)
    sessionStorage.setItem('identityId', identityId)
  }


  /**
   * @func: clearUserCredentials
   *
   * @description: elimina de sessionStorage las credenciales del usuário
   */
  clearUserCredentials () {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('identityId')
  }


  /**
   * @func: isUserAuthenticated
   * @param {[Function]} callback
   * @return: {[Boolean]}
   *
   * @description: Devuelve si un usuario está autenticado comprobando si sus datos
   * están almacenados en sessionStorage.
   */
  isUserAuthenticated () {
    if (sessionStorage.getItem('accessToken')) return true
    return false
  }
}

export default new AuthStore()
