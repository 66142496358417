/**
 * @type {Object}
 * @description: Contiene las constantes y variuables que siven para la
 * configuración de la aplicación y son compartidas por diferentes containers,
 * componentes y stores.
 */
const config = {
  REGION: "eu-west-1",
  API_KEY: "hTspbMY3nP8VgmkHOeXW16CeyeqFzWxb7ZYydbYQ", // DEV
  //API_KEY: 'hFIIQ02amX3SjNJvzSisr2qkin40r2Vj1pMBQaah',  // PRO A
  //API_KEY: 'bMhPPDQUvm9a1xxRqZgLQ1Cox4PPUrci2ZBH2zgF', // PRO B
  API: null,
  IDENTITY_POOL_ID: "eu-west-1:39a9011e-348d-45e3-af20-0384b7d48539", // DEV
  //IDENTITY_POOL_ID: 'eu-west-1:7c0e0645-1983-46e2-825b-4612bf9d38c7',  // PRO
  //GAPS: 'UA-89202806-4', // PRO
  GAPS: "G-B4ZVBTLP68", //DEV
  LWS_URL_V3: "https://xandar-lsw-v3.instaleap.io/",
  LWS_URL_V2: "https://lsw.instaleap.io/?env=staging",
  // LWS_URL_V3: "",
  // LWS_URL_V2: "",
  S3_CATALOGUES: "",
  S3_IMAGES: "",
  ABSOLUTE_PATH: "https://market.ilusia.com",
  CUSTOMER_SUPPORT:
    "https://v2.zopim.com/widget/popout.html?key=4BPg8VUFClto9gFT1tuZqBlJ4MxyLgqM",

  C4M_ENABLE: 1,

  LANGUAGE: "ES",

  CURRENCY: "€",

  ICON_SIZE: 33,

  COLORS: {
    BLUE: "#00BFDF",
  },

  // Separación entre productos de las baldas
  PRODUCTS_GAP: 40,
  // Numero maximo de baldas
  MAX_ROWS: 3,
  MAX_SELVES: 3,
  MAX_SELVES_SHOP_BOT: 2,
  // Margen lateral de las baldas
  SHELF_MARGIN: 400,
  // Proporción de tamaño para las imagenes del lineal
  SHELF_IMAGE_SIZE: 16,
  // proporción de tamaño para las imagenes del detalle
  DETAIL_IMAGE_SIZE: 50,
  DETAIL_FAKE_BAG_IMAGE_SIZE: 46,
  PRICE_TAG_IMAGE_SIZE: 46,
  MIN_LAST_ACTIVITY_USER_AUTO_LOGIN: 7200, // Seconds,
  MAX_AUTOLOGIN_SESSION_TIME: 7200, // seconds

  HAMMER_OPTIONS: {
    touchAction: 'none',
    recognizers: {
      // swipe: {
      //   enable: true,
      //   direction: 6,
      //   vertical: false,
      // },
      pan: {
        enable: true,
        vertical: false,
        threshold: 5
      },
    },
  },

  FACEBOOK_APP_ID: "1215506051904672",
  TWITTER_APP_ID: "791604771874537472",

  METAS: {
    TWITTER: {
      title: "ilusia®. The Smart Shopping Experience",
      description:
        "ilusia® revoluciona la compra online. El único marketplace multiplataforma donde podrás comprar como si estuvieras en el supermercado. ¿Aún no lo has probado?",
      image:
        "https://pbs.twimg.com/profile_images/801095400463749120/oxADaE6y_400x400.jpg",
    },
    FACEBOOK: {
      title: "ilusia®. The Smart Shopping Experience",
      description:
        "ilusia® revoluciona la compra online. El único marketplace multiplataforma donde podrás comprar como si estuvieras en el supermercado. ¿Aún no lo has probado?",
      image:
        "https://scontent.fmad3-1.fna.fbcdn.net/v/t1.0-1/p200x200/14718653_1019307338215055_7736408751857078037_n.png",
      url: "http://www.ilusia.com",
    },
  },

  AVAILABLE_PROVIDERS: [
    {
      icon: "cart_carrefour",
      id: 2,
      bubblecolor: "#2850B5",
      title: "Carrefour",
      login: null,
      pass: null,
      status: 0,
      URL: "http://www.carrefour.es/supermercado/Bienvenida.aspx",
      dec: 0.5,
    },
    {
      icon: "cart_dia",
      id: 5,
      bubblecolor: "#DE172A",
      title: "Dia",
      login: null,
      pass: null,
      status: 0,
      URL: "https://www.dia.es/compra-online/login/singlelogin/registerview",
      dec: 0.5,
    },
    {
      icon: "cart_ilusia",
      id: 20,
      bubblecolor: "#00BFDF",
      title: "Ilusia",
      login: null,
      pass: null,
      status: 0,
      URL: "",
      dec: 0,
    },
    {
      icon: "cart_mercadona",
      id: 3,
      bubblecolor: "#229E6B",
      title: "Mercadona",
      login: null,
      pass: null,
      status: 0,
      URL: "https://www.mercadona.es/ns/entrada.php?js=1",
      dec: 0.5,
    },
    {
      icon: "cart_eroski",
      id: 4,
      bubblecolor: "#DF1727",
      title: "Eroski",
      login: null,
      pass: null,
      status: 0,
      URL: "https://www.compraonline.grupoeroski.com/",
      dec: 0.5,
    },
    {
      icon: "cart_mas",
      id: 6,
      bubblecolor: "#C0D35A",
      title: "Mas",
      login: null,
      pass: null,
      status: 0,
      URL: "https://tienda.supermercadosmas.com/tiendavirtual/Registro.do",
      dec: 0.1,
    },
    {
      icon: "cart_ilusia",
      id: 10,
      bubblecolor: "#00BFDF",
      title: "Ilusia",
      login: null,
      pass: null,
      status: 0,
      URL: "",
      dec: 0,
    },
  ],

  // NOTE: Las bolsas de papas
  FAKE_BAGS: ["3bb5_113309.png", "421b_113308.png"],
  FAKE_BAGS_SIZES: [143, 350],

  CART_CONFIG: {
    abandoned_cart_notification_sent: 0,
    status: 0,
    postcode: null,
    payment_method: 0,
    language: "es",
    address_billing: 0,
    address_shipping: 0,
    id_delivery_time_slot: 0,
    comments: "",
    replace_confirm: 1,
    replace_phone: 1,
    voucher: "",
    user_type: 1,
    navigator_language: navigator.language,
    booking_type: 0
  },

  // Configuracion para los componentes de ruta que no piden datos
  // pero necesitan esperar a que AppStore esté configurado
  NO_DATA: {
    toolbar: false,
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: ["providers", "categories", "layouts", "shop", "detail"],
    resetConfigData: [
      "SHOP_CONFIG",
      "PROVIDER_SELECTION_CONFIG",
      "SHOPPINGCART_CONFIG",
      "PRODUCT_DETAIL_CONFIG",
      "TRAMITAR_CONFIG",
    ],
    data: [],
  },

  REGISTRATION: {
    toolbar: "simple",
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: ["providers", "categories", "layouts", "shop", "detail"],
    resetConfigData: [
      "SHOP_CONFIG",
      "PROVIDER_SELECTION_CONFIG",
      "SHOPPINGCART_CONFIG",
      "PRODUCT_DETAIL_CONFIG",
      "TRAMITAR_CONFIG",
    ],
    data: [],
  },

  // Configuraciones para los componentes de ruta (AsyncRouteManager)
  ZONE_SELECTION_CONFIG: {
    toolbar: "simple",
    background: ["video/video_mobile", "video"],
    hasData: false,
    resetStoreData: [
      "postalcode",
      "providers",
      "categories",
      "layouts",
      "shop",
      "detail",
      "iframeurl",
    ],
    resetConfigData: [
      "SHOP_CONFIG",
      "PROVIDER_SELECTION_CONFIG",
      "SHOPPINGCART_CONFIG",
      "PRODUCT_DETAIL_CONFIG",
      "TRAMITAR_CONFIG",
    ],
    data: [],
  },
  PROVIDER_SELECTION_CONFIG: {
    toolbar: "simple",
    background: ["video/video_mobile", "video"],
    hasData: false,
    resetStoreData: ["categories", "layouts", "shop", "detail", "iframeurl"],
    resetConfigData: [
      "SHOP_CONFIG",
      "SHOPPINGCART_CONFIG",
      "PRODUCT_DETAIL_CONFIG",
      "TRAMITAR_CONFIG",
    ],
    data: ["countries", "allergens", "welfares", "providers"],
  },
  SHOP_CONFIG: {
    toolbar: "custom",
    background: ["", "custom"],
    hasData: false,
    resetStoreData: ["detail", "allergenActive"],
    resetConfigData: ["PRODUCT_DETAIL_CONFIG", "TRAMITAR_CONFIG"],
    data: [
      //"countries",
      //"allergens",
      //"welfares",
      //"providers",
      "categories",
      "layouts",
      "shop",
      //"tutorial",
    ],
  },
  TRAMITAR_CONFIG: {
    toolbar: "custom",
    background: ["", "custom"],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [
      //"countries",
      //"allergens",
      //"welfares",
      //"providers",
      "categories",
      "layouts",
      "shop",
      "iframeurl",
    ],
  },
  SHOPBOT_CONFIG: {
    toolbar: "custom",
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: ["detail"],
    resetConfigData: ["PRODUCT_DETAIL_CONFIG", "TRAMITAR_CONFIG"],
    data: [
      //"countries",
      //"allergens",
      //"welfares",
      //"providers",
      "categories",
      "layouts",
      "shop",
    ],
  },
  SHOPPINGCART_CONFIG: {
    toolbar: "custom",
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: ["detail"],
    resetConfigData: ["PRODUCT_DETAIL_CONFIG", "TRAMITAR_CONFIG"],
    data: [
      //"countries",
      //"allergens",
      //"welfares",
      //"providers",
      "categories",
      "layouts",
      "shop",
    ],
  },
  PRODUCT_DETAIL_CONFIG: {
    toolbar: "custom",
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: ["detail"],
    resetConfigData: ["PRODUCT_DETAIL_CONFIG", "TRAMITAR_CONFIG"],
    data: [
      //"countries",
      //"allergens",
      //"welfares",
      //"providers",
      "categories",
      "layouts",
      "shop",
      "detail",
    ],
  },
  USER_ZONE_CONFIG: {
    toolbar: "custom",
    background: [
      { firstcolor: "#00BFDF", lastcolor: "#00BFDF", angle: 0 },
      "gradient",
    ],
    backgroundMobile: [
      { firstcolor: "#F0F4F8", lastcolor: "#F0F4F8", angle: 0 },
"gradient"
    ],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [
      "orders"
    ],
  },
  ORDER_DETAIL_CONFIG: {
    toolbar: "custom",
    background: [
      { firstcolor: "#00BFDF", lastcolor: "#00BFDF", angle: 0 },
      "gradient",
    ],
    hasData: false,
    resetStoreData: ["order", "idorder"],
    resetConfigData: ["ORDER_DETAIL_CONFIG"],
    data: ["order"],
  },
  EXPORT_CART_CONFLICTS: {
    toolbar: "default",
    background: ["/img/bg_new_white.jpg", "img"],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [],
  },
  NOT_PROVIDER_CONFIG: {
    toolbar: "simple",
    background: [
      { firstcolor: "#00BFDF", lastcolor: "#00BFDF", angle: 0 },
      "gradient",
    ],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [],
  },
  POTENTIAL_PROVIDER_CONFIG: {
    toolbar: "simple",
    background: [
      { firstcolor: "#00BFDF", lastcolor: "#00BFDF", angle: 0 },
      "gradient",
    ],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [],
  },
  ORDER_CONFIRMATION_CONFIG: {
    toolbar: "custom",
    background: [
      { firstcolor: "#FFFFFF", lastcolor: "#FFFFFF", angle: 0 },
      "gradient",
    ],
    hasData: false,
    resetStoreData: [],
    resetConfigData: [],
    data: [],
  },

  MASCPLIST: [
    { cp: "10001", tittle: "10001 - CACERES (CACERES)" },
    { cp: "10002", tittle: "10002 - CACERES (CACERES)" },
    { cp: "10003", tittle: "10003 - CACERES (CACERES)" },
    { cp: "10004", tittle: "10004 - CACERES (CACERES)" },
    { cp: "10005", tittle: "10005 - CACERES (CACERES)" },
    { cp: "10070", tittle: "10070 - CACERES (CACERES)" },
    { cp: "10071", tittle: "10071 - CACERES (CACERES)" },
    { cp: "10080", tittle: "10080 - CACERES (CACERES)" },
    { cp: "10195", tittle: "10195 - CACERES (CACERES)" },
    { cp: "11001", tittle: "11001 - CADIZ (CADIZ)" },
    { cp: "11002", tittle: "11002 - CADIZ (CADIZ)" },
    { cp: "11003", tittle: "11003 - CADIZ (CADIZ)" },
    { cp: "11004", tittle: "11004 - CADIZ (CADIZ)" },
    { cp: "11005", tittle: "11005 - CADIZ (CADIZ)" },
    { cp: "11006", tittle: "11006 - CADIZ (CADIZ)" },
    { cp: "11007", tittle: "11007 - CADIZ (CADIZ)" },
    { cp: "11008", tittle: "11008 - CADIZ (CADIZ)" },
    { cp: "11009", tittle: "11009 - CADIZ (CADIZ)" },
    { cp: "11010", tittle: "11010 - CADIZ (CADIZ)" },
    { cp: "11011", tittle: "11011 - CADIZ (CADIZ)" },
    { cp: "11100", tittle: "11100 - SAN FERNANDO (CADIZ) (CADIZ)" },
    { cp: "18001", tittle: "18001 - GRANADA (GRANADA)" },
    { cp: "18002", tittle: "18002 - GRANADA (GRANADA)" },
    { cp: "18003", tittle: "18003 - GRANADA (GRANADA)" },
    { cp: "18004", tittle: "18004 - GRANADA (GRANADA)" },
    { cp: "18005", tittle: "18005 - GRANADA (GRANADA)" },
    { cp: "18006", tittle: "18006 - GRANADA (GRANADA)" },
    { cp: "18007", tittle: "18007 - GRANADA (GRANADA)" },
    { cp: "18008", tittle: "18008 - GRANADA (GRANADA)" },
    { cp: "18009", tittle: "18009 - GRANADA (GRANADA)" },
    { cp: "18010", tittle: "18010 - GRANADA (GRANADA)" },
    { cp: "18011", tittle: "18011 - GRANADA (GRANADA)" },
    { cp: "18012", tittle: "18012 - GRANADA (GRANADA)" },
    { cp: "21006", tittle: "21006 - HUELVA (HUELVA)" },
    { cp: "21007", tittle: "21007 - HUELVA (HUELVA)" },
    { cp: "21122", tittle: "21122 - HUELVA (HUELVA)" },
    { cp: "21130", tittle: "21130 - MAZAGON (HUELVA)" },
    { cp: "21710", tittle: "21710 - BOLLULLOS DEL CONDADO (HUELVA)" },
    { cp: "29001", tittle: "29001 - MALAGA (MALAGA)" },
    { cp: "29002", tittle: "29002 - MALAGA (MALAGA)" },
    { cp: "29003", tittle: "29003 - MALAGA (MALAGA)" },
    { cp: "29004", tittle: "29004 - MALAGA (MALAGA)" },
    { cp: "29005", tittle: "29005 - MALAGA (MALAGA)" },
    { cp: "29006", tittle: "29006 - MALAGA (MALAGA)" },
    { cp: "29007", tittle: "29007 - MALAGA (MALAGA)" },
    { cp: "29008", tittle: "29008 - MALAGA (MALAGA)" },
    { cp: "29009", tittle: "29009 - MALAGA (MALAGA)" },
    { cp: "29010", tittle: "29010 - MALAGA (MALAGA)" },
    { cp: "29011", tittle: "29011 - MALAGA (MALAGA)" },
    { cp: "29012", tittle: "29012 - MALAGA (MALAGA)" },
    { cp: "29013", tittle: "29013 - MALAGA (MALAGA)" },
    { cp: "29014", tittle: "29014 - MALAGA (MALAGA)" },
    { cp: "29015", tittle: "29015 - MALAGA (MALAGA)" },
    { cp: "29016", tittle: "29016 - MALAGA (MALAGA)" },
    { cp: "29017", tittle: "29017 - MALAGA (MALAGA)" },
    { cp: "29018", tittle: "29018 - MALAGA (MALAGA)" },
    { cp: "29620", tittle: "29620 - TORREMOLINOS (MALAGA)" },
    { cp: "29630", tittle: "29630 - BENALMADENA COSTA (MALAGA)" },
    { cp: "29639", tittle: "29639 - BENALMADENA PUEBLO (MALAGA)" },
    { cp: "29780", tittle: "29780 - NERJA (MALAGA)" },
    { cp: "41001", tittle: "41001 - SEVILLA (SEVILLA)" },
    { cp: "41002", tittle: "41002 - SEVILLA (SEVILLA)" },
    { cp: "41003", tittle: "41003 - SEVILLA (SEVILLA)" },
    { cp: "41004", tittle: "41004 - SEVILLA (SEVILLA)" },
    { cp: "41005", tittle: "41005 - SEVILLA (SEVILLA)" },
    { cp: "41006", tittle: "41006 - SEVILLA (SEVILLA)" },
    { cp: "41007", tittle: "41007 - SEVILLA (SEVILLA)" },
    { cp: "41008", tittle: "41008 - SEVILLA (SEVILLA)" },
    { cp: "41009", tittle: "41009 - SEVILLA (SEVILLA)" },
    { cp: "41010", tittle: "41010 - SEVILLA (SEVILLA)" },
    { cp: "41011", tittle: "41011 - SEVILLA (SEVILLA)" },
    { cp: "41012", tittle: "41012 - SEVILLA (SEVILLA)" },
    { cp: "41013", tittle: "41013 - SEVILLA (SEVILLA)" },
    { cp: "41014", tittle: "41014 - SEVILLA (SEVILLA)" },
    { cp: "41015", tittle: "41015 - SEVILLA (SEVILLA)" },
    { cp: "41016", tittle: "41016 - SEVILLA (SEVILLA)" },
    { cp: "41017", tittle: "41017 - SEVILLA (SEVILLA)" },
    { cp: "41018", tittle: "41018 - SEVILLA (SEVILLA)" },
    { cp: "41019", tittle: "41019 - SEVILLA (SEVILLA)" },
    { cp: "41020", tittle: "41020 - SEVILLA (SEVILLA)" },
    { cp: "41070", tittle: "41070 - SEVILLA (SEVILLA)" },
    { cp: "41071", tittle: "41071 - SEVILLA (SEVILLA)" },
    { cp: "41080", tittle: "41080 - SEVILLA (SEVILLA)" },
    { cp: "41089", tittle: "41089 - MONTEQUINTO (SEVILLA)" },
    { cp: "41092", tittle: "41092 - SEVILLA (SEVILLA)" },
    { cp: "41100", tittle: "41100 - CORIA DEL RIO (SEVILLA)" },
    { cp: "41110", tittle: "41110 - BOLLULLOS DE LA MITACION (SEVILLA)" },
    { cp: "41111", tittle: "41111 - ALMENSILLA (SEVILLA)" },
    { cp: "41120", tittle: "41120 - GELVES (SEVILLA)" },
    { cp: "41130", tittle: "41130 - LA PUEBLA DEL RIO (SEVILLA)" },
    { cp: "41200", tittle: "41200 - ALCALA DEL RIO (SEVILLA)" },
    { cp: "41209", tittle: "41209 - SAN IGNACIO DEL VIAR (SEVILLA)" },
    { cp: "41210", tittle: "41210 - GUILLENA (SEVILLA)" },
    { cp: "41218", tittle: "41218 - TORRE DE LA REINA (SEVILLA)" },
    { cp: "41219", tittle: "41219 - LAS PAJANOSAS (SEVILLA)" },
    { cp: "41220", tittle: "41220 - BURGUILLOS (SEVILLA)" },
    { cp: "41300", tittle: "41300 - SAN JOSE DE LA RINCONADA (SEVILLA)" },
    { cp: "41309", tittle: "41309 - LA RINCONADA (SEVILLA)" },
    { cp: "41310", tittle: "41310 - BRENES (SEVILLA)" },
    { cp: "41318", tittle: "41318 - VILLAVERDE DEL RIO (SEVILLA)" },
    { cp: "41319", tittle: "41319 - EL VIAR (SEVILLA)" },
    { cp: "41410", tittle: "41410 - CARMONA (SEVILLA)" },
    { cp: "41500", tittle: "41500 - ALCALA DE GUADAIRA (SEVILLA)" },
    { cp: "41510", tittle: "41510 - MAIRENA DEL ALCOR (SEVILLA)" },
    { cp: "41520", tittle: "41520 - VISO DEL ALCOR (SEVILLA)" },
    { cp: "41700", tittle: "41700 - DOS HERMANAS (SEVILLA)" },
    { cp: "41701", tittle: "41701 - DOS HERMANAS (SEVILLA)" },
    { cp: "41702", tittle: "41702 - DOS HERMANAS (SEVILLA)" },
    { cp: "41703", tittle: "41703 - DOS HERMANAS (SEVILLA)" },
    { cp: "41704", tittle: "41704 - DOS HERMANAS (SEVILLA)" },
    { cp: "41710", tittle: "41710 - UTRERA  (SEVILLA)" },
    { cp: "41728", tittle: "41728 - ADRIANO (SEVILLA)" },
    { cp: "41800", tittle: "41800 - SANLUCAR LA MAYOR (SEVILLA)" },
    { cp: "41804", tittle: "41804 - OLIVARES (SEVILLA)" },
    { cp: "41805", tittle: "41805 - BENACAZON (SEVILLA)" },
    { cp: "41806", tittle: "41806 - UMBRETE (SEVILLA)" },
    { cp: "41807", tittle: "41807 - ESPARTINAS (SEVILLA)" },
    { cp: "41808", tittle: "41808 - VILLANUEVA DEL ARISCAL (SEVILLA)" },
    { cp: "41809", tittle: "41809 - ALBAIDA DEL ALJARAFE (SEVILLA)" },
    { cp: "41820", tittle: "41820 - CARRION DE LOS CESPEDES (SEVILLA)" },
    { cp: "41830", tittle: "41830 - HUEVAR DE ALJARAFE (SEVILLA)" },
    { cp: "41840", tittle: "41840 - PILAS (SEVILLA)" },
    { cp: "41849", tittle: "41849 - AZNALCáZAR (SEVILLA)" },
    { cp: "41860", tittle: "41860 - GERENA (SEVILLA)" },
    { cp: "41880", tittle: "41880 - EL HOYUELO (SEVILLA)" },
    { cp: "41900", tittle: "41900 - CAMAS (SEVILLA)" },
    { cp: "41907", tittle: "41907 - VALENCINA DE LA CONCEPCION (SEVILLA)" },
    { cp: "41908", tittle: "41908 - CASTILLEJA DE GUZMAN (SEVILLA)" },
    { cp: "41909", tittle: "41909 - SALTERAS (SEVILLA)" },
    { cp: "41910", tittle: "41910 - EL CARAMBOLO (SEVILLA)" },
    { cp: "41920", tittle: "41920 - SAN JUAN DE AZNALFARACHE (SEVILLA)" },
    { cp: "41927", tittle: "41927 - MAIRENA DEL ALJARAFE (SEVILLA)" },
    { cp: "41928", tittle: "41928 - PALOMARES DEL RIO (SEVILLA)" },
    { cp: "41930", tittle: "41930 - BORMUJOS (SEVILLA)" },
    { cp: "41940", tittle: "41940 - TOMARES (SEVILLA)" },
    { cp: "41950", tittle: "41950 - CASTILLEJA DE LA CUESTA (SEVILLA)" },
    { cp: "41960", tittle: "41960 - GINES (SEVILLA)" },
    { cp: "41970", tittle: "41970 - SANTIPONCE (SEVILLA)" },
    { cp: "41980", tittle: "41980 - LA ALGABA (SEVILLA)" },
    { cp: "41989", tittle: "41989 - EL ARAL (SEVILLA)" },
  ],

  MASTYPE: [
    { tipo: "AGRUP" },
    { tipo: "ALDEA" },
    { tipo: "APTO." },
    { tipo: "AUTOVIA" },
    { tipo: "AVDA." },
    { tipo: "BAJADA" },
    { tipo: "BARRANCO" },
    { tipo: "BARRIADA" },
    { tipo: "BARRIO" },
    { tipo: "CALLE" },
    { tipo: "CAMINO" },
    { tipo: "CASA" },
    { tipo: "CHALET" },
    { tipo: "COLONIA" },
    { tipo: "CTRA." },
    { tipo: "CUESTA" },
    { tipo: "EDIF." },
    { tipo: "GLORIETA" },
    { tipo: "GRAN VIA" },
    { tipo: "GRUPO" },
    { tipo: "LUGAR" },
    { tipo: "PLAZA" },
    { tipo: "TORREJON" },
    { tipo: "URB." },
  ],
};

export default config;
