/*****************************************************************************
 * @class: AppStore
 *
 * @description: Este store es el encargado de manejar la lógica de datos de la
 * aplicación. El estado de los datos de la aplicación se ha de encargar el store
 * y los componentes no necesitan conocer el estado de los datos que consumen,
 * ellos tan solo saben que necesitan ciertos datos y los piden.
 *
 *****************************************************************************/

import "aws-sdk/dist/aws-sdk";
import React from "react";
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import { observable, computed, action, reaction, autorun } from "mobx";
import config from "../config";
import {
  AuthStore,
  SyncStore,
  ShopStore,
  SearchStore,
  UIStore,
  OrderStore,
  Tracking,
} from "./";
import {
  asyncLoop,
  getDataAsync,
  calcCategorieViews,
  splitURL,
  urliFy,
  sortBy,
  sortByCategoryIndex,
  capitalize,
  AssetsPreloader,
  setCookie,
  getCookie,
  deleteCookie,
  isTouchDevice
} from "../utils";
import { ProductModel } from "../models";
import {
  getCountries,
  getProviders,
  getProductsCategory,
  getCategories,
  getLayoutIds,
  getLayouts,
  getAllergens,
  getWelfares,
  getSvgIcons,
  getProductInfo,
  getProductNutritionalInfo,
  getSuggested,
  getOrderProducts,
  getIntegrationsDIASSO,
  getMasCartInformation,
} from "../utils/api";
import { Notification } from "../components/Dialogs/Notification/components";
import ReactGA from "react-ga";

class AppStore {
  // Variable para controlar el switch de alergenos y welfares.

  allergenActive = 0;
  // Array para controlar los alergenos activos.
  allergensEnable = [];
  welfaresEnable = [];

  // Variable para el id de campaña de C4M

  ccid = null;

  // Estas variables las utilizo para almacenar los datos que pide la primera
  // vista a la que accededmos a la app (para los casos en que no es root)
  // mientras configuramos todo lo necesario en AuthStore
  firstCallStack = [];
  firstCallCallback = null;

  // Variable para obtener los datos de resmen al finalizar MAS
  CatInformationMas = null;
  // Nombre del pais seleccionado
  countryname = null;
  // Código postal seleccionado
  postalcode = null;
  // Código de zona seleccionado
  idzone = null;
  // Nombre del proveedor seleccionado
  providername = null;
  // Nombre del proveedor seleccionado en el historico de pedidos
  orderprovidername = null;
  // Ide del Último pedido exportado.
  lastOrderID = null;
  // Paises disponibles
  countries = null;
  idcountry = 1;
  // Proveedores disponibles para un CP
  providers = null;
  // Proveedor seleccionado
  idprovider = null;
  // proveedores cargados en config.AVAILABLE_PROVIDERS -> Se utiliza en el Provider selection.
  providerconfigloaded = [];
  // id_delivery_time_slot
  iddeliverytimeslot = null;
  // precio del delivery
  pricedelivery = null;

  @observable provider = null;
  // Categorias disponibles para el proveedor seleccionado
  categories = null;
  idcategory = null;
  @observable currentcategoryName = null;
  // Categorias descargadas
  downloadCategories = {};
  nextCategory = null;
  previousCategory = null;
  // Vista del lineal
  view = 0;
  // Control del proceso de carga de la tienda
  shop = null;
  @observable currentView;
  @observable previousView;
  @observable nextView;
  @observable currentLayout;
  @observable previousLayout;
  @observable nextLayout;
  // Layouts disponibles para el proveedor seleccionado
  layouts = null;
  // IDs de los layouts disponibles para el proveedor seleccionado
  layoutIds = null;
  // Alergenos disponibles
  allergens = null;
  // Cuidate disponibles
  welfares = null;
  // Iconos para los filtros nutricionales
  // NOTE: deberia ser un observable para que cuando los tengamos se
  // actualize el componente que los renderiza.
  svgIcons = null;
  // Objeto Map() para almacenar los modelos de los productos
  products = null;

  // Detalle de producto
  detail = null;
  iddetail = null;
  productname = null;

  // Detalle de pedido
  order = null;
  // Controla la selección de la vista en la zona de usuario
  @observable userZoneView = 1;
  @observable userZoneViewPlaceholder = "Pedidos";
  // Orden seleccionado por el usuario.
  shopField = "categoryEntryIndex";
  shopBack = false;
  shopPrimer = parseInt;
  // Instancia del Dropdown para poder resetearlo
  // NOTE: Lo seteamos en el metodo componentDidMount del componente 'ShopLine'
  dropdownInstance = null;

  tutorial = null;
  tutorialuser = null;

  // URL para el iframde de DIA y pago de MAS
  iframeurl = null;

  // Variables para diseño responsive
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  isTouchScreen = isTouchDevice();
  @observable reviewOrderProductImageSize = window.innerWidth <= 600 ? 12 : 16;
  
  // Historico de pedidos
  orders = null;
  activeParentCategoryId = null;
  secondLevelCategoryId = null;
  currentCategoryId = null;

  /**
   * @func: initialize
   * @description: Este método es ejecutado desde 'AuthStore' una vez disponemos
   * de los datos de configuración necesários para hacer las llamadas a la API.
   *
   * En primer lugar pedimos los datos basicos que no requieren de parámetros
   * seleccionados por el usuario. Una vez obtenemos estos datos podemos pedir
   * los SVGs de los iconos para los filtros.
   *
   * En segundo lugar, si el usuario está autenticado, pedimos su histórico
   * de pedidos para pintar el Dropdown de la Toolbar
   */
  initialize = () => {
    this.recordUserActivity();
    if(getCookie("cookie-analytic") === "no"){
      deleteCookie("_gat", "/", document.domain);
      deleteCookie("_gid", "/", document.domain);
      deleteCookie("_ga", "/", document.domain);
      window[`ga-disable-${config.GAPS}`] = true;
    }
    ReactGA.initialize(config.GAPS);

    console.log("Initialize AppStore");
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("ccid") == true) {
      this.ccid = urlParams.get("ccid");
      console.log("Proviene de campaña " + this.ccid);
    } else console.log("No proviene de ninguna campaña de C4M");

    this.products = new Map();
    this.componentDataHandler(this.request, this.firstCallCallback);

    if (window.location.pathname !== '/cookies' && getCookie("cookie-dialog") !== "yes" && getCookie("cookie-analytic") !== "yes") {
      //UIStore.showCookiesDialog(); Update this
      //setCookie("cookie-dialog", encodeURIComponent("yes"), null);
    }

    // Inicializamos Facebook
    //FB.init({ appId: config.FACEBOOK_APP_ID, xfbml: true, version: "v2.9" });
    console.log("Finished initialized")
  };

  /**
   * @func: componentDataHandler
   * @param  {[Object]} request - Objeto que contiene el Array con los datos a gestionar
   * @param {[Function]} callback
   * @return {[Function]} callback
   *
   * @description: Este método se encarga de manejar los datos que requieren los
   * componentes que maneja el contenedor 'AsyncRouteManager'.
   *
   * TODO: comprobar si los datos están disponibles antes de pedirlos.
   */
  componentDataHandler = (request, callback) => {
    if (!request) return;
    this.request = request;

    this.firstCallCallback = callback;
    // NOTE: si aún no hemos confugurado AuthStore
    // almacenamos los datos de la primera llamada
    if (AuthStore.configured === false) return;

    const url = splitURL();
    this.countryname = this.countryname || "España";
    this.postalcode = this.postalcode || url[1];
    // NOTE: modificado
    this.providername = this.providername || url[2];

    // NOTE: para el detalle de producto.
    // No necesitamos el nombre para nada, solo se pinta en la URL
    // para hacerla mas amigable.
    this.iddetail = url[1] || undefined;
    this.productname = url[2] || undefined;

    return asyncLoop(
      request.data.length,
      (loop) => {
        let i = loop.index();
        console.info(
          `%cPidiendo los datos para: %c${request.data[i]}`,
          "color: blue",
          "color: blue; font-weight: bold"
        );

        // Comprobamos el estado de los datos solicitados.
        // Si disponemos de ellos saltamos a la siguiente iteración.
        if (this[request.data[i]] !== null) {
          console.log(this[request.data[i]])
          console.info(
            `%cNo necesitamos pedir los datos para: %c${request.data[i]}`,
            "color: green",
            "color: green; font-weight: bold"
          );
          loop.next();
          return;
        }

        // Si aún no disponemos de los datos componemos el nombre del método que
        // necesitamos para solicitarlos
        let name =
          request.data[i].charAt(0).toUpperCase() + request.data[i].slice(1);
        let fn = `_get${name}`;
        // Ejecutamos la llamada asíncrona a la API
        this[fn]()
          .then((result) => {
            // Ejecutamos la siguiente iteración
            loop.next();
            return result;
          })
          .catch((error) => {
            throw error;
          });
      },
      () => {
        request.hasData = true;
        if (callback) callback();
      }
    );
  };

  /**
   * Se encarga de comprobar si hemos visto el tuto y actuar en consecuencia.
   */
  async _getTutorial() {
    if (!this.authenticated && getCookie("tutorial") !== "yes") {
      UIStore.toggleTutorial();
      setCookie("tutorial", encodeURIComponent("yes"), null);
    }

    this.tutorial = true;
  }

  async _getTutorialuser() {
    if (!this.authenticated && getCookie("tutorialuser") !== "yes") {
      UIStore.toggleTutorialuser();
      setCookie("tutorialuser", encodeURIComponent("yes"), null);
    }

    this.tutorialuser = true;
  }

  /**
   * Obtenemos los paises
   */
  async _getCountries() {
    const countries = await getDataAsync(getCountries);
    this.countries = countries;
  }

  /**
   * Obtenemos los alergenos
   */
  async _getAllergens() {
    const allergens = await getDataAsync(getAllergens, config.LANGUAGE);
    this.allergens = allergens;
  }

  /**
   * Obtenemos los cuidate
   */
  async _getWelfares() {
    const welfares = await getDataAsync(getWelfares, config.LANGUAGE);
    this.welfares = welfares;
  }

  /**
   * Obtenemos los iconos de los filtros
   */
  async getSvgIcons() {
    const all = this.allergens.map((item) => item.icon);
    const wel = this.welfares.map((item) => item.icon);
    const filters = all.concat(wel);

    const icons = await getDataAsync(getSvgIcons, filters);
    this.svgIcons = icons;
  }

  /**
   * Obtenemos los proveedores disponibles para un CP
   * Si no existen proveedores redirigimos a la vista especial
   * NOTE: 41650 no tiene proveedores
   */
  async _getProviders() {
    const providers = await getDataAsync(
      getProviders,
      this.postalcode,
      this.idcountry
    );
    console.log('esto es lo que devuelve providers')
    console.log(providers) 
    if (providers.length < 1 || !Array.isArray(providers)) {
      console.warn(
        `%cNo hay proveedores para la zona %c${this.postalcode}`,
        "color: red",
        "color: red; font-weight: bold"
      );
      this.providers = [];
      browserHistory.push("/no-existen-proveedores");
    } else {
      this.providers = providers;
    }
    for (let item in config.AVAILABLE_PROVIDERS) {
      this.providerconfigloaded.push(config.AVAILABLE_PROVIDERS[item].id);
    }
    /*
    for (var prov in providers){
      console.log(prov)
    }*/
  }

  /**
   * Obtenemos las categorias y creamos el menu
   */
  async _getCategories() {
    //this.idprovider = this.providers.filter(
      //(p) => p.provider === this.providername.toUpperCase()
    //)[0].id_provider;
    //this.provider = this.providers.filter(
      //(p) => p.id_provider === this.idprovider
    //)[0];
    console.log({
      providertwo: this.provider
    })
    //this.providername = capitalize(this.provider.provider);
    //this.idzone = this.provider.id_zone;
    const categories = await getDataAsync(
      getCategories,
      this.idprovider,
      this.idzone,
      "es"
    );
    // console.log(JSON.stringify(categories, null, 2))
    this.categories = categories;
  }

  /**
   * Obtenemos los layouts de las categorías.
   */
  async _getLayouts() {
    this.layoutIds = getLayoutIds(this.categories).join();
    // El id layout 55 es el que se usa para las busquedas debe estar siempre.
    if (this.layoutIds.indexOf('55') === -1) this.layoutIds = this.layoutIds + ',55'
    const layouts = await getDataAsync(
      getLayouts,
      this.layoutIds,
      this.idprovider
    );
    this.layouts = this.mapArrayIntoMap(layouts, "id_layout");

    //console.log(layouts);
    //console.log(this.layouts);
    // console.log(this.layouts);
    // NOTE: desde aquí le pasamos el fondo de la primera categoría al componente
    // de ruta para que lo pida cuando corresponde, al iniciar la transición.
    // TODO: esto hay que cambiarlo cuando la API devuelva los datos correectos
    // this.request.background = [`https://images.ilusia.com/post_masters/${ layouts[0].background }`, 'img']
  }

  /**
   * Inicia el proceso de carga y cálculo de la tienda.
   * NOTE: mientras creamos el menú de categorías saco las categorias
   * con productos en esta función.
   */
  async _getShop() {
    // NOTE: Una vez disponemos del id del proveedor podemos inicializar la sincro.
    // Comprobamos si existe el dates carts. Si no existe lo creamos y a su vez
    // crearemos en el un record para el proveedor seleccionado.
    // Una vez hemos comprobado que existe carts y el respectivo record, o bien
    // los hemos creado, pediremos o crearemos el dataset 'cart_' para gestionar el
    // carrito de la compra del usuario para el proveedor seleccionado.
    console.log("Me inicializo SHOP");
    /*
    SyncStore.initialize(records => {
        if (records.length > 0) SyncStore.mapProducts(records)
      })*/
    if (!SyncStore.initialized) {
      SyncStore.initialize((records) => {
        if (records.length > 0) SyncStore.mapProducts(records);
      });
    }

    let defaultCategory;
    this.downloadCategories = new Map();

    this.categories.forEach((category) => {
      // TODO: desardcodear la categoría 4147
      // if (category.children.length === 0 && category.products > 0 && category.id_category !== 4147) {
      if (category.children.length === 0 && category.products > 0) {
        this.downloadCategories.set(category.id_category, category);
        if (!defaultCategory) defaultCategory = category.id_category;
      }
    });

    // NOTE: llamada a getProducts para la primera categoría
    const products = await this.getProducts(
      defaultCategory,
      this.categoryLoaded
    );
    await this.mapProducts(products, defaultCategory);
    window.APP = { products };
    UIStore.resizeWindowHandler();
    this.setViews();
    this.setBackground();
    console.log({
      productsShop: products
    })
    return products;
  }

  /**
   * Obtiene la info necesaria para pintar el detalle de producto
   */
  async _getDetail() {
    this.detail = this.products.get(parseInt(this.iddetail));
    this.nutritional = null;

    if (!this.detail) {
      const product = await getDataAsync(
        getProductInfo,
        parseInt(this.iddetail),
        this.idzone,
        this.idprovider,
        config.LANGUAGE
      );
      this.productname = urliFy(product.description);
      this.detail = new ProductModel(product, undefined, null, 0);
      this.products.set(product.id_product_eva, this.detail);
    }

    // NOTE: esto es un parche por si las categorias no vienen como Array
    if (
      Object.prototype.toString.call(this.detail.product.categories) ===
      "[object String]"
    )
      this.detail.product.categories = [this.detail.product.categories];

    // TODO: controlar que el producto existe, de lo contrario
    // redirigimos al lineal y mostramos el correspondiente mensaje
    // TODO: hay que eliminar el 'idcategory' de la llamada a sugeridos
    // cuando lo preparen en CORE

    // NOTE: controlamos si tiene ingredientes o info nutricional.
    //   0 No tiene ningún tipo de información.
    //   1 Solo tiene información adicional.
    //   2 Solo tiene ingredientes.
    //   3 Tiene información adicional e ingredientes.
    //   4 Si no tiene ningún tipo de información y tiene tabla.
    //   5 Si tiene información adicional y tabla.
    //   6 Si tiene ingredientes y tabla.
    //   7 Si tiene información adicional, nutricional y tabla.

    if (this.detail.product.has_nutritional_info !== 0) {
      const nutritional = await getDataAsync(
        getProductNutritionalInfo,
        this.iddetail,
        config.LANGUAGE
      );
      this.nutritional = nutritional || null;
    }

    const suggested = await getDataAsync(
      getSuggested,
      this.iddetail,
      this.idzone,
      this.idprovider,
      this.idcategory,
      "[]",
      "[]",
      config.LANGUAGE
    );
    this.suggested = suggested || undefined;
  }

  /**
   * El truco está en el callback, si venimos de la primera carga o del menú
   * de categorías el callback apuntará a 'categoryLoaded'. Si venimos de la
   * paginación del lineal el callback apuntará a TODO.
   */
  async getProducts(idcategory, callback) {
    // console.log(idcategory);
    console.info(
      `%cPidiendo productos para la categoría %c${
        this.downloadCategories.get(idcategory).name
      }`,
      "color: blue",
      "color: blue; font-weight: bold"
    );
    const products = await getDataAsync(
      getProductsCategory,
      this.idprovider,
      this.idzone,
      idcategory
    );
    // console.log(JSON.stringify(products, null, 2));
    // TODO: solo para desarrollo de CORE
    // if (!products.length) {
    //   alert(`¡La llamada a getProducts para la categoría ${ this.downloadCategories.get(idcategory).name } no devuelve productos!`)
    // }
    // NOTE: creamos los modelos y los añadimos a nuestro Map products
    await this.mapProducts(products, idcategory);
    // console.log(this.products);
    if (callback) return callback(idcategory, products);
  }

  /**
   * El truco está en el callback, si venimos de la primera carga o del menú
   * de categorías el callback apuntará a 'categoryLoaded'. Si venimos de la
   * paginación del lineal el callback apuntará a TODO.
   */
  async _getOrder(idorder, cp, callback) {
    // console.log(idcategory);
    const paths = browserHistory.getCurrentLocation().pathname.split("/");
    console.info(
      `%cPidiendo el detalle del pedido %c${this.idorder || paths[4]}`,
      "color: blue",
      "color: blue; font-weight: bold"
    );
    OrderStore.idorder = OrderStore.idorder || paths[4];
    OrderStore.order = await getDataAsync(
      getOrderProducts,
      OrderStore.idorder,
      config.LANGUAGE
    );
    this.order = OrderStore.order;
    OrderStore.providername = paths[3];
    OrderStore.postalcode = paths[2];

    const providers = await getDataAsync(
      getProviders,
      cp || paths[2],
      this.idcountry
    );
    if (providers.length === 0 || !Array.isArray(providers)) {
      console.warn(
        `%cNo hay proveedores para la zona %c${paths[2]}`,
        "color: red",
        "color: red; font-weight: bold"
      );
    } else {
      const provider = providers.filter(
        (p) => capitalize(p.provider) === OrderStore.providername
      )[0];
      OrderStore.idzone = provider.id_zone;
      OrderStore.idprovider = provider.id_provider;
      OrderStore.providername = provider.provider;
    }

    if (callback) return callback(this.idorder, order);
  }

  async _getOrders() {
    await AuthStore.getProcessedOrders();
  }

  /**
   * Una vez tenemos los productos de una categoría cargados,
   * seteamos las vistas para la categoria y calculamos las categorias
   * adyacentes para pedir sus productos y setear las vistas.
   */
  categoryLoaded = (idcategory, products) => {
    this.idcategory = idcategory;
    this.addViewsToCategory(idcategory, products);
    return this.setAdjacentCategories(
      idcategory,
      this.getAdjacentCategoriesProducts
    );
  };

  /**
   * Localiza las categorias adyacentes a la categoria actual y pide sus productos
   * si es necesário.
   */
  async setAdjacentCategories(idcategory, callback) {
    let previousView;
    let nextView;
    let id = parseInt(idcategory);
    this.previousCategory = this.getPreviousCategory(id);
    this.nextCategory = this.getNextCategory(id);

    if (callback) return callback();
  }

  /**
   * Pedimos los productos para las categorias adyacentes.
   *
   * NOTE: controlamos que tengan mas de una vista y si no pedimos la siguiente categoría
   */
  getAdjacentCategoriesProducts = async () => {
    if (!this.previousCategory.views)
      await this.getProducts(
        this.previousCategory.id_category,
        this.addViewsToCategory
      );
    if (!this.nextCategory.views)
      await this.getProducts(
        this.nextCategory.id_category,
        this.addViewsToCategory
      );

    if (this.previousCategory.views.length < 2) {
      const cat = this.getPreviousCategory(this.previousCategory.id_category);
      console.info(
        `%cLa categoria anterior solo tiene una vista. Vamos a pedir: %c${cat.name}`,
        "color: blue",
        "color: blue; font-weight: bold"
      );
      if (!cat.views)
        await this.getProducts(cat.id_category, this.addViewsToCategory);
    }

    if (this.nextCategory.views.length < 2) {
      const cat = this.getNextCategory(this.nextCategory.id_category);
      console.info(
        `%cLa siguiente categoria solo tiene una vista. Vamos a pedir: %c${cat.name}`,
        "color: blue",
        "color: blue; font-weight: bold"
      );
      if (!cat.views)
        await this.getProducts(cat.id_category, this.addViewsToCategory);
    }
  };

  /**
   * Obtiene la siguiente categorías
   */
  getNextCategory(id) {
    // TODO: aunque los spread operators son bastante interesantes se podría cambiar por Array.from()
    const categories = [...this.downloadCategories.values()];
    const size = this.downloadCategories.size;
    for (let i = 0; i < size; i++) {
      if (categories[i].id_category === id) {
        const next = i === size - 1 ? categories[0] : categories[i + 1];
        console.log(
          `%cLa siguiente categoría es: %c${next.name}`,
          "color: blue",
          "color: blue; font-weight: bold"
        );
        return next;
      }
    }
  }

  /**
   * Obtiene la siguiente categorías
   */
  getPreviousCategory(id) {
    // TODO: aunque los spread operators son bastante interesantes se podría cambiar por Array.from()
    const categories = [...this.downloadCategories.values()];
    const size = this.downloadCategories.size;
    for (let i = 0; i < size; i++) {
      if (categories[i].id_category === id) {
        const prev = i === 0 ? categories[size - 1] : categories[i - 1];
        console.log(
          `%cLa categoría anterior es: %c${prev.name}`,
          "color: blue",
          "color: blue; font-weight: bold"
        );
        return prev;
      }
    }
  }

  /**
   * Crea las vistas para las categorias descargadas
   */
  addViewsToCategory = async (idcategory, products, callback) => {
    let cat = this.downloadCategories.get(parseInt(idcategory));
    console.info(
      `%cCargada la categoria %c${cat.name}`,
      "color: green",
      "color: green; font-weight: bold"
    );
    // NOTE: guardamos los productos en la categoría para poder
    // acceder facil a ello cuando reordenemos el lineal.
    const models = await products.map((product) =>
      this.products.get(parseInt(product.id_product_eva))
    );
    // NOTE: si el usuario ha seleccionado un orden reordenamos los
    // nuevos productos que vamos obteniendo de la API
    cat.products = await models.sort(
      sortBy(
        this.shopField,
        this.shopBack,
        this.shopPrimer,
        idcategory.toString()
      )
    );

    cat.views = await calcCategorieViews(models, true);
    this.downloadCategories.set(idcategory, cat);

    if (callback) return callback();
  };

  findCategoryParentId(categotyId){
    let parentId;
    try {
      parentId = this.categories.filter(category => category.id_category === categotyId)[0].id_parent;
    } catch (e){
      console.log(e);
    }

    return parentId;
  }

  /**
   * Setea las vistas para el carrusel y setea 'shop' a true para ejecutar
   * el callback del metodo 'asyncLoop' que devuelve 'componentDataHandler'.
   *
   * NOTE: Son observables para que el componente 'ShopLine' se renderize cada
   * vez que modifiquemos las vistas.
   */
  setViews() {
    console.info(
      `%cSeteando las vistas del lineal`,
      "color: yellow; background: grey;"
    );
    try {
      const currCat = this.downloadCategories.get(this.idcategory);
      const prevCat = this.previousCategory;
      const nextCat = this.nextCategory;

      // NOTE: current view
      if (currCat.views[this.view]) {
        this.currentView = currCat.views[this.view];
        this.currentcategoryName = capitalize(currCat.name);

        // Update currentCategoryId and activeParentCategoryId for show current categoryId in Menu
        if (currCat.level === 3){
          const parentCategoryId = this.findCategoryParentId(currCat.id_parent);
          if (parentCategoryId){
            this.secondLevelCategoryId = currCat.id_parent;
            this.currentCategoryId = currCat.id_category;
            this.activeParentCategoryId = parentCategoryId;
          }
        }

      } else {
        this.currentView = nextCat.views[0];
        this.currentcategoryName = capitalize(nextCat.name);

        // Update currentCategoryId and activeParentCategoryId for show current categoryId in Menu
        if (nextCat.level === 3){
          const parentCategoryId = this.findCategoryParentId(nextCat.id_parent);
          if (parentCategoryId){
            this.secondLevelCategoryId = nextCat.id_parent;
            this.currentCategoryId = nextCat.id_category;
            this.activeParentCategoryId = parentCategoryId;
          }
        }
        
      }

      // NOTE: previous view
      if (this.view === 0) {
        this.previousView = prevCat.views[prevCat.views.length - 1];
      } else {
        this.previousView = currCat.views[this.view - 1];
      }

      // NOTE: next view
      if (currCat.views[this.view + 1]) {
        this.nextView = currCat.views[this.view + 1];
      } else {
        this.nextView = nextCat.views[0];
      }

      // TODO: esto deberia ser una función
      // Seteamos los layouts de las baldas
      // Previous
      if (this.view === 0) {
        this.previousLayout = prevCat.id_layout;
      } else {
        this.previousLayout = currCat.id_layout;
      }
      // Current
      this.currentLayout = currCat.id_layout;
      // next
      if (this.view === currCat.views.length - 1) {
        this.nextLayout = nextCat.id_layout;
      } else {
        this.nextLayout = currCat.id_layout;
      }

      UIStore.hideLoading();
      if (this.shop === null) this.shop = true;
    } catch (e) {
      UIStore.hideLoading();
      console.error(
        `There was an error loading ${this.nextCategory.name} category. This category or any of its products may not be displayed correctly.`
      );
    }
  }

  /**
   * Controla la paginación del carrusel
   */
  paginateShop(dir) {
    switch (dir) {
      case "prev":
        this.view--;
        break;
      case "next":
        this.view++;
        break;
    }

    if (
      this.view >= this.downloadCategories.get(this.idcategory).views.length
    ) {
      this.idcategory = this.nextCategory.id_category;
      this.view = 0;
    }

    if (this.view < 0) {
      this.idcategory = this.previousCategory.id_category;
      if (this.previousCategory.views.length === 1) {
        this.view = 0;
      } else {
        this.view = this.previousCategory.views.length - 1;
      }
    }

    this.setAdjacentCategories(
      this.idcategory,
      this.getAdjacentCategoriesProducts
    );
    this.setViews();
  }

  /**
   * Se llama desde el método start de la animación
   */
  setBackground(dir) {
    const currentCategory = this.downloadCategories.get(this.idcategory);
    let curr;

    // NOTE: Si solo hay una vista en la categoria
    if (currentCategory.views.length === 1) {
      curr = currentCategory;
      if (dir === "next") curr = this.nextCategory;
      if (dir === "prev") curr = this.previousCategory;
    } else {
      if (this.view === 0) {
        // NOTE: si estoy en la primera vista
        curr = currentCategory;
        if (dir === "next") curr = currentCategory;
        if (dir === "prev") curr = this.previousCategory;
      } else if (this.view === currentCategory.views.length - 1) {
        // NOTE: Si estoy en la ultima vista
        curr = currentCategory;
        if (dir === "next") curr = this.nextCategory;
        if (dir === "prev") curr = currentCategory;
      } else {
        // NOTE: Si estoy en una vista intermedia
        curr = currentCategory;
      }
    }

    const bg = this.layouts.get(curr.id_layout);
    // NOTE: como el fondo de la tienda depende de la categoría necesitamos
    // almacenar en la configuración de la vista la ultima imagen de fondo
    // por si navegamos por otras vistas y volvemos a la tienda.
    config.SHOP_CONFIG.background = [
      `https://images.ilusia.com/post_masters/${bg.background}`,
      "img",
    ];
    UIStore.setBackground(
      `https://images.ilusia.com/post_masters/${bg.background}`,
      "img"
    );
  }

  /**
   * Navega a una categoría por su ID.
   * Si ya tenemos las vistas no pedimos los productos.
   * NOTE: Se utiliza desde los botones del menu de categorias.
   */
  goToCategory = async (id) => {
    UIStore.showLoading();

    // NOTE: reseteamos el orden seleccionado por el usuario
    await this.resetSortShop();

    this.view = 0;
    if (!this.downloadCategories.get(parseInt(id)).views) {
      await this.getProducts(parseInt(id), this.categoryLoaded);
    } else {
      this.idcategory = parseInt(id);
    }

    this.setBackground();
    await this.setAdjacentCategories(
      parseInt(id),
      this.getAdjacentCategoriesProducts
    );
    this.setViews();
  };

  /**
   * Obtenemos la URL del Iframe de DAI.
   */
  async _getIframeurl() {
    if (this.idprovider == 5) {
      const diaurl = await getDataAsync(getIntegrationsDIASSO);
      this.iframeurl = diaurl;
      if (typeof diaurl.url === "undefined") {
        //UIStore.showNotification({ type: 'danger', content: () => <Notification message="Parece que su cuenta vinculada no está actualizada. Por favor, compruebe sus datos e inténtelo de nuevo." /> })
        browserHistory.push(
          `/${this.countryname}/${this.postalcode}/${this.providername}`
        );
      }
    } else if (this.idprovider != 6) {
      browserHistory.push(
        `/${this.countryname}/${this.postalcode}/${this.providername}`
      );
    } else {
      const MasInformation = await getMasCartInformation();
      this.CatInformationMas = MasInformation.data;
      if (this.CatInformationMas.prices.shopping_price == 0) {
        browserHistory.push(
          `/${this.countryname}/${this.postalcode}/${this.providername}`
        );
        UIStore.showNotification({
          type: "danger",
          timeOut: 3000,
          content: () => (
            <Notification message="No tienes ningún pedido pendiente de pagar en Supermercados Mas" />
          ),
        });
      } else {
        this.iframeurl = "http";
      }
    }
  }

  /**
   * Setea y aplica el orden seleccionado por el usuario
   */
  async sortShop(field, back, primer) {
    this.shopField = field;
    this.shopBack = back;
    this.shopPrimer = primer;

    await this.sortProducts();

    this.view = 0;
    this.setViews();
  }

  /**
   * Resetea todo lo relaccionado con el orden de tienda.
   */
  async resetSortShop() {
    this.shopField = "categoryEntryIndex";
    this.shopBack = false;
    this.shopPrimer = parseInt;

    if (this.dropdownInstance) this.dropdownInstance.setActiveItem(0);

    await this.sortProducts();
  }

  /**
   * Ordena los productos de las categorías que tenemos descargadas
   */
  async sortProducts() {
    Array.from(this.downloadCategories).forEach((category) => {
      if (category[1].products.length) {
        const products = category[1].products.sort(
          sortBy(
            this.shopField,
            this.shopBack,
            this.shopPrimer,
            category[0].toString()
          )
        );
        category[1].views = calcCategorieViews(products, true);
        category[1].products = products;
      }
    });
  }

  /**
   * Crea los modelos observables para los productos y los
   * añade al objeto map 'this.products'.
   *
   * NOTE: si el producto no tiene imagen le pasamos las 'bosas de papas'.
   */
  mapProducts(array, idcategory) {
    if (!array) return;

    array.forEach((product, index) => {
      if (!product.image)
        product.image = config.FAKE_BAGS[Math.round(Math.random())];

      let model = this.products
        ? this.products.get(product.id_product_eva)
        : null;
      if (!model) {
        model = new ProductModel(product, index, null, 0);
        model.setIndex(idcategory, index);
        this.products.set(product.id_product_eva, model);
      } else {
        model.setIndex(idcategory, index);
      }
    });
  }

  /**
   * Mapea un Array en un objeto Map.
   * @prop: {Array} iterable
   * @prop: {String} key
   */
  mapArrayIntoMap(iterable, key) {
    const map = new Map();
    for (let item of iterable) {
      map.set(item[key], item);
    }
    return map;
  }

  /**
   * Controla el reescalado del navegador.
   */
  resizeHandler() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.isTouchScreen = isTouchDevice();
    this.reviewOrderProductImageSize = window.innerWidth <= 600 ? 12 : 16;
    
    Array.from(this.downloadCategories).forEach((category) => {
      if (category[1].products.length) {
        category[1].views = calcCategorieViews(category[1].products, true);
      }
    });
    this.view = 0;
    this.setViews();
  }

  /**
   * Reseteamos los datos de la tienda.
   * NOTE: A estas funciónes de reset se las llama desde AsyncRouteManager
   * al cual se las seteamos como propiedades al instanciarlo.
   */
  @action resetStoreData = (props) => {
    for (let prop of props) {
      this[prop] = null;
      if (prop === "shop") {
        ShopStore.reset();
        SyncStore.reset();
        SearchStore.reset();
        this.downloadCategories = null;
        this.view = 0;
        this.providername = null;
        this.idprovider = null;
        this.provider = null;
        this.currentcategoryName = null;
        this.childCategories = [];
        this.tutorial = null;
        this.tutorialuser = null;
        this.products.clear();
        this.allergensEnable = [];
        this.welfaresEnable = [];
        this.iframeurl = null;
      }
    }
  };

  /**
   * Reseteamos los componentes de ruta (hasData).
   */
  resetConfigData = (props) => {
    for (let prop of props) {
      if (config[prop].hasData) config[prop].hasData = false;
    }
  };

  recordUserActivity() {
    const updateLastUserActivity = () => {
      const timestamp = Date.now();
      localStorage.setItem('lastUserActivity', timestamp);
    };

    document.addEventListener('touchstart', () => {
      updateLastUserActivity();
    });

    document.addEventListener('mousedown', () => {
      updateLastUserActivity();
    });
}

}

export default new AppStore();
