import React, { Component } from "react";
import { translate } from "react-i18next";
import { observer } from "mobx-react";
import { AppStore, ShopStore, UIStore, SyncStore, AuthStore } from "../../stores";
import { IconButton, Dropdown } from "../";

// NOTE: opciones para el Dropdown
const SHOPPINGCART_OPTIONS = [
  {
    text: "Ver como cuadrícula",
    active: true,
    action: () => ShopStore.toggleView(0),
  },
  {
    text: "Ver como ticket",
    active: false,
    action: () => ShopStore.toggleView(1),
  },
  {
    text: "Vaciar carrito",
    selectable: false,
    active: false,
    action: () => ShopStore.emptyCart(),
  },
];

@translate(["common"], { wait: false })
@observer
class ShoppingCartHeader extends Component {
  componentDidMount() {
    window.setTimeout(() => {
      ShopStore.dropdownInstance = this.child;
      window.clearTimeout(this);
    });
    UIStore.hideLoading();
  }
  componentWillUnmount() {
    UIStore.hideLoading();
  }

  render() {
    const total = ShopStore.price.toFixed(2) - ShopStore.saving.toFixed(2);
    var saving = null;
    if (parseInt(ShopStore.saving) > 0) {
      const savingText = "Te ahorras " + ShopStore.saving.toFixed(2) + " €";
      saving = <p className="cart_saving_deal"> {savingText} </p>;
    }
    return (
      <div>
        <div className="shopping-cart_herader">
          <div className="left box-md-6">
            {saving}
            <p className="p regular black s14">{ShopStore.units} ARTÍCULOS:</p>
            <p className="p regular dark-grey total s14">Total (Sin envío):</p>
            <h1 className="h1 regular black s38">{total.toFixed(2)} €</h1>
          </div>
          <div className="right box-md-6">
            <IconButton
              type="rounded"
              color="green"
              iconType="send_order"
              onClick={() => this.checkCart()}
            />
            <Dropdown
              options={SHOPPINGCART_OPTIONS}
              ref={(instance) => {
                this.child = instance;
              }}
              className="shopping-cart_dropdown"
              content="list"
              buttonType="icon"
              buttonColor="grey"
              placeholder="Custom Dropdown"
              iconType="dots"
              align="right"
              expandOnHoover={false}
              collapseOnLeave={false}
              setSelectedAsPlaceholder={false}
              transitionName="modal-anim"
            />
          </div>
        </div>
      </div>
    );
  }

  checkCart = () => {
    UIStore.showLoading();
    SyncStore.cartsConfig.get(
      AppStore.idprovider.toString(),
      (error, value) => {
        UIStore.hideLoading();
        if (!value) {
          SyncStore.checkCartStatus(null, (cart) => {
            ShopStore.preSendCart();
          });
        } else {
          ShopStore.preSendCart();
        }
      }
    );
  };
}

export default ShoppingCartHeader;
