import React, { Component } from 'react';
import { observer } from "mobx-react";
import { translate } from "react-i18next";
import { ShopStore, UIStore } from '../stores';


class OrderPayment extends Component{
  render(){
    return(
      <div className="section order-payment">
        <div className="container-payment">
          <iframe 
            src={ShopStore.url}
            className="iframe"
          />
        </div>
      </div>
    )
  }
}

export default OrderPayment;