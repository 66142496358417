import React, { Component } from 'react';

class ErrorView extends Component {
  render() {
    return (
      <div className="error-view bg-gradient">
        <div className="absolute-center container">
          <div className="error-content">
            <h2 className="h2 light s32 white">Por favor, inicia sesión para acceder a esta página.</h2>
          </div>
          <div className="error-image">
            <img src="../img/img_error.png" alt="imagen de error" />
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorView;
